import { Button, Group, LoadingOverlay, Space, Title } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm, type UseFormReturnType } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowLeft,
  IconCopyCheck,
  IconDeviceFloppy,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import ContractualStepper from '../../../../components/ContractualStepper';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CompanyService from '../../../../services/CompanyService';
import ProfileService from '../../../../services/ProfileService';
import type { CompanyGroup } from '../../../../types/api/payload/company';
import {
  CountryAreaEnum,
  CountryEnum,
} from '../../../../types/api/response/company';
import {
  ContractRegime,
  ContractType,
  type IdentifiantCompany,
  type UserContractualInfoType,
  type UserPersonalInfo,
  type UserPersonalSensitive,
  type UserResponse,
} from '../../../../types/api/response/user';

type Props = {
  onClose: () => void;
  userProfile: UserResponse;
  refetch: () => void;
  userContractualInfo?: UserContractualInfoType | null;
  setActive: (number: number) => void;
  active: number;
  setContractualInfo: (data: UserContractualInfoType) => void;
  refetchUserContractualInfo?: () => void;
  validateEmployeeOnBoarding: (variables: {
    userId: string;
    employee: boolean;
    accountant: boolean;
  }) => void;
  refetchOBInfos?: () => void;
  finalValidateEmployeeOnBoarding: (variables: { userId: string }) => void;
  userPersonalInfo: UserPersonalInfo | null;
  userSensitiveInfo?: UserPersonalSensitive | null;
  setSelectedCompanyGroup: (data: string | null) => void;
};

export type ContractFormValues = {
  id?: string | null;
  userId: string;
  professionalQualifications: string;
  contractType: ContractType;
  otherContractType?: string | null;
  payslipName: string;
  regime: ContractRegime;
  regimeLevel: string;
  otherRegime?: string | null;
  weeklyHours?: number | null;
  monthlyHours?: number | null;
  yearlyHours?: number | null;
  grossPay?: number | null;
  entryDate?: Date | string | null;
  exitDate?: Date | string | null;
  employmentAuthorizationDate?: Date | string | null;
  layoffAuthorizationDate?: Date | string | null;
  comment: string;
  file: FileWithPath[];
  identifiantsCompany: IdentifiantCompany[] | [];
  employment: string;
  countryCode: CountryEnum;
  countryArea?: CountryAreaEnum;
};

export type AddEmployeeWorkContractFormValues = UseFormReturnType<
  ContractFormValues,
  (values: ContractFormValues) => ContractFormValues
>;

export default function AddEmployeeWorkContractForm({
  onClose,
  userProfile,
  refetch,
  userContractualInfo,
  setActive,
  active,
  setContractualInfo,
  refetchUserContractualInfo,
  validateEmployeeOnBoarding,
  refetchOBInfos,
  finalValidateEmployeeOnBoarding,
  userPersonalInfo,
  userSensitiveInfo,
  setSelectedCompanyGroup,
}: Props) {
  const { t } = useI18n();
  const { user, access_token } = useAuth();
  const [file, setFile] = useState<FileWithPath[]>([]);
  const { company } = useCompany(userProfile);

  const { data: companyGroupList } = useQuery({
    enabled: !!userProfile.company?.id,
    queryKey: ['CompanyService.listCompanyGroup', userProfile.company?.id],
    queryFn: () => CompanyService.listCompanyGroup(userProfile.company?.id!),
  });

  const companyGroupSelect = useMemo(() => {
    if (
      !companyGroupList ||
      companyGroupList.filter(
        (item: CompanyGroup) => item.siret !== company?.siret
      ).length < 1
    ) {
      return [];
    } else {
      return companyGroupList.map((companyGroup: CompanyGroup) => {
        return {
          value: companyGroup.id,
          label: companyGroup.label,
        };
      });
    }
  }, [companyGroupList]);

  const form: AddEmployeeWorkContractFormValues = useForm<ContractFormValues>({
    initialValues: {
      id: userContractualInfo?.id ? userContractualInfo.id : null,
      userId: userProfile.id,
      professionalQualifications:
        userContractualInfo?.professionalQualifications
          ? userContractualInfo.professionalQualifications
          : '',
      contractType: userContractualInfo?.contractType
        ? userContractualInfo.contractType
        : ContractType.CDI,
      otherContractType: userContractualInfo?.otherContractType
        ? userContractualInfo.otherContractType
        : null,
      payslipName: userContractualInfo?.payslipName
        ? userContractualInfo.payslipName
        : '',
      regime: userContractualInfo?.regime
        ? userContractualInfo.regime
        : ContractRegime.ETAM,
      regimeLevel: userContractualInfo?.regimeLevel
        ? userContractualInfo.regimeLevel
        : '',
      otherRegime: userContractualInfo?.otherRegime
        ? userContractualInfo.otherRegime
        : null,
      weeklyHours: userContractualInfo?.weeklyHours
        ? userContractualInfo.weeklyHours
        : null,
      monthlyHours: userContractualInfo?.monthlyHours
        ? userContractualInfo.monthlyHours
        : null,
      yearlyHours: userContractualInfo?.yearlyHours
        ? userContractualInfo.yearlyHours
        : null,
      grossPay: userContractualInfo?.grossPay
        ? userContractualInfo.grossPay
        : null,
      entryDate: userContractualInfo?.entryDate
        ? dayjs(userContractualInfo.entryDate).toDate()
        : null,
      exitDate: userContractualInfo?.exitDate
        ? dayjs(userContractualInfo.exitDate).toDate()
        : null,
      employmentAuthorizationDate:
        userContractualInfo?.employmentAuthorizationDate
          ? dayjs(userContractualInfo.employmentAuthorizationDate).toDate()
          : null,
      layoffAuthorizationDate: userContractualInfo?.layoffAuthorizationDate
        ? dayjs(userContractualInfo.layoffAuthorizationDate).toDate()
        : null,
      comment: userContractualInfo?.comment ? userContractualInfo.comment : '',
      file: [],
      identifiantsCompany: userProfile.identifiantsCompany
        ? userProfile.identifiantsCompany
        : [],
      employment: userProfile.employment ? userProfile.employment : '',
      countryCode: userContractualInfo?.countryCode
        ? userContractualInfo.countryCode
        : user.company?.countryCode
        ? user.company.countryCode
        : CountryEnum.FR,
      countryArea: userContractualInfo?.countryArea
        ? userContractualInfo.countryArea
        : user?.company?.countryArea,
    },
  });

  const {
    mutate: createOrUpdateUserContractualInfo,
    isLoading: isLoadingCreateOrUpdateUserContractualInfo,
  } = useMutation({
    mutationFn: (
      variables: Omit<
        ContractFormValues,
        'file' | 'employment' | 'identifiantsCompany'
      >
    ) =>
      ProfileService.createOrUpdateUserContractualInfo(
        userProfile.id,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      if (file.length > 0) {
        updateContract();
      }
      if (refetchOBInfos) {
        refetchOBInfos();
      }
      showNotification({
        id: 'udpate-added-employee-work-contract-success',
        title: t('w.success'),
        message: t('success.contractualInfoUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setContractualInfo(data);
      if (refetchUserContractualInfo) {
        refetchUserContractualInfo();
      }
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserContractualInfo', userProfile.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateEmployee, isLoading: isLoadingUpdateEmmployee } =
    useMutation({
      mutationFn: (variables: {
        identifiantsCompany: IdentifiantCompany[] | [] | undefined;
        employment: string | undefined;
      }) => ProfileService.updateUserProfile(userProfile.id, variables, null),
      onSuccess: () => {
        showNotification({
          id: 'udpate-added-employee-information-success',
          title: t('w.success'),
          message: t('success.informationUpdated'),
          color: 'green',
          icon: <IconCheck />,
        });
        refetch();
      },
      onError: () =>
        showNotification({
          id: 'update-employee-added-error',
          title: t('w.error'),
          message: t('error.updateUserProfile', userProfile.firstname),
          color: 'red',
          icon: <IconX />,
        }),
    });

  const { mutate: updateContract } = useMutation({
    mutationFn: () =>
      ProfileService.updateContract(userProfile.id, file[0], access_token),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-work-contract-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserContractualInfo', userProfile.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateAddedEmployeeWorkContract(values: ContractFormValues) {
    createOrUpdateUserContractualInfo({
      id: values.id !== null ? values.id : null,
      userId: userProfile.id,
      professionalQualifications: values.professionalQualifications,
      contractType: values.contractType,
      otherContractType:
        values.contractType === 'OTHER' ? values.otherContractType : null,
      payslipName: values.payslipName,
      regime: values.regime,
      regimeLevel: values.regimeLevel,
      otherRegime: values.regime === 'OTHER' ? values.otherRegime : null,
      weeklyHours: values.weeklyHours,
      monthlyHours: values.monthlyHours,
      yearlyHours: values.yearlyHours,
      grossPay: values.grossPay,
      entryDate: values.entryDate
        ? dayjs(values.entryDate).format('YYYY-MM-DDTHH:mm:ss')
        : null,
      exitDate: values.exitDate
        ? dayjs(values.exitDate).format('YYYY-MM-DD')
        : null,
      employmentAuthorizationDate: values.employmentAuthorizationDate
        ? dayjs(values.employmentAuthorizationDate).format('YYYY-MM-DD')
        : null,
      layoffAuthorizationDate: values.layoffAuthorizationDate
        ? dayjs(values.layoffAuthorizationDate).format('YYYY-MM-DD')
        : null,
      comment: values.comment,
      countryCode: values.countryCode,
      countryArea: values.countryArea,
    });

    if (values.file != null && values.file?.length > 0) {
      setFile(values.file);
    }

    updateEmployee({
      identifiantsCompany: values.identifiantsCompany,
      employment: values.employment,
    });
  }

  function openConfirmOnBoarding(values: ContractFormValues) {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title:
        user.id === userProfile.id ? (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfos')}
          </Title>
        ) : userProfile.onBoarding?.onBoardingEmployee?.validationDate !==
            null &&
          userProfile.onBoarding?.onBoardingCompany !== null &&
          userProfile.onBoarding?.onBoardingCompany?.validationDate &&
          userProfile.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null ? (
          <Title size={'h3'} component="p">
            {"Vous allez finaliser l'embauche de votre collaborateur"}
          </Title>
        ) : (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfosCompany')}
          </Title>
        ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () => validateOnBoarding(values),
    });
  }

  function validateOnBoarding(values: ContractFormValues) {
    if (userProfile !== null) {
      if (user.id !== userProfile.id) {
        if (
          userProfile.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          userProfile?.onBoarding?.onBoardingCompany !== null &&
          userProfile.onBoarding?.onBoardingCompany?.validationDate &&
          userProfile?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null
        ) {
          finalValidateEmployeeOnBoarding({ userId: userProfile.id });
        } else {
          validateEmployeeOnBoarding({
            userId: userProfile.id,
            employee: false,
            accountant: false,
          });
        }
      } else {
        validateEmployeeOnBoarding({
          userId: userProfile.id,
          employee: true,
          accountant: false,
        });
      }
      handleUpdateAddedEmployeeWorkContract(values);
    }
  }

  function isDisabledConfirmOnBoardingButton() {
    const isRequiredInfoMissing =
      userSensitiveInfo?.identityCardNumber === null ||
      userSensitiveInfo?.identityCardNumber === '' ||
      userSensitiveInfo?.ssNumber === null ||
      userSensitiveInfo?.ssNumber === '' ||
      userPersonalInfo?.address === null ||
      userPersonalInfo?.birthPlace === null ||
      userPersonalInfo?.birthDepartment === null ||
      userPersonalInfo?.birthday === null;

    const isMyProfileAndAlreadyValidated =
      userProfile?.id === user.id &&
      userProfile?.onBoarding?.onBoardingEmployee?.validationDate !== null;

    const isNotMyProfileAndNotValidatedYet =
      userProfile?.id !== user.id &&
      userProfile?.onBoarding?.onBoardingEmployee?.validationDate === null;

    return (
      isRequiredInfoMissing ||
      isMyProfileAndAlreadyValidated ||
      isNotMyProfileAndNotValidatedYet
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateAddedEmployeeWorkContract(values)
      )}
    >
      <LoadingOverlay
        visible={
          isLoadingCreateOrUpdateUserContractualInfo || isLoadingUpdateEmmployee
        }
      />
      <Title order={3}>{t('w.workContract')}</Title>
      <Space h={'xl'} />
      {userProfile && (
        <ContractualStepper
          form={form}
          userProfile={userProfile}
          setSelectedCompanyGroup={setSelectedCompanyGroup}
          companyGroupSelect={companyGroupSelect}
          companyGroupList={companyGroupList}
          company={company}
        />
      )}
      <Group justify={'flex-end'} mt={'51px'} gap={'30px'}>
        {Boolean(userProfile.onBoardingId) && (
          <Button
            onClick={() => openConfirmOnBoarding(form.values)}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={isDisabledConfirmOnBoardingButton()}
          >
            {userProfile.onBoarding?.onBoardingEmployee?.validationDate !==
              null &&
            userProfile.onBoarding?.onBoardingCompany !== null &&
            userProfile.onBoarding?.onBoardingCompany?.validationDate &&
            userProfile.onBoarding?.onBoardingCompany?.validationDate
              ?.dateOfUpdate !== null
              ? t('w.finalizeOnboarding')
              : t('w.validateInfos')}
          </Button>
        )}
        <Button
          disabled={
            Boolean(userProfile.onBoardingId) &&
            userProfile.id === user.id &&
            userProfile.onBoarding?.onBoardingEmployee?.validationDate !== null
          }
          leftSection={<IconDeviceFloppy />}
          type={'submit'}
        >
          {t('w.save')}
        </Button>
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
          variant={'outline'}
        >
          {t('w.back')}
        </Button>

        <Button
          onClick={onClose}
          variant={'outline'}
          disabled={
            Boolean(userProfile.onBoardingId) && userProfile.id === user.id
          }
        >
          {t('w.close')}
        </Button>
      </Group>
    </form>
  );
}
