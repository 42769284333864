import { Button, SimpleGrid, Skeleton, Title } from '@mantine/core';
import { DateValue } from '@mantine/dates';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconBrandPocket,
  IconCheck,
  IconTableDown,
  IconTableImport,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useMemo, useState } from 'react';

import CustomMenu from '../../../../components/CustomMenu';
import CustomMonthPickerInput from '../../../../components/CustomMonthPickerInput';
import MonthEventScheduler from '../../../../components/scheduler/MonthEventScheduler';
import { useI18n } from '../../../../contexts/I18nProvider';
import ScreenWrapper from '../../../../core/layouts/components/ScreenWrapper';
import DailyWorkService from '../../../../services/DailyWorkService';
import TimeRecorderService from '../../../../services/TimeRecorderService';
import type {
  DailyWorkDetails,
  LeaveDetails,
} from '../../../../types/api/response/dailyWork';
import {
  LeaveTypeResponse,
  LeaveTypeTag,
} from '../../../../types/api/response/leaveType';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../types/api/response/role';
import type { UserResponse } from '../../../../types/api/response/user';
import type { UserDailyWork } from '../../../../types/front/dailyWork';
import type { TimeRecorder } from '../../../../types/types';
import { hasPermission } from '../../../../utils/authorization';
import { convertMinutesToHourMinutes } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { htmlToPdf } from '../../../../utils/htmlToPdf';
import replaceSpaceWithString from '../../../../utils/replaceSpaceWithString';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import type { IconName } from '../../../company/period-types/components/IconSelector';
import DashboardItem from './DashboardItem';
import ImportActivitiesForm from './ImportActivitiesForm';

type Props = {
  userProfile: UserResponse;
};

export default function DailyWorkMode({ userProfile }: Props) {
  const { t } = useI18n();
  const [selectedDate, setSelectedDate] = useState<DateValue>(new Date());

  const { mutate: exportUserDailyWork } = useMutation({
    mutationFn: (variables: { month: number; year: number }) =>
      DailyWorkService.exportUserDailyWork(
        userProfile.id,
        variables.month,
        variables.year
      ),
    onSuccess: (data) => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-user-dailywork-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-user-dailywork-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  const { data, refetch } = useQuery({
    queryKey: [
      'DailyWorkService.getUserDailyWork',
      userProfile.id,
      dayjs(selectedDate).month() + 1,
      dayjs(selectedDate).year(),
    ],
    queryFn: () =>
      DailyWorkService.getUserDailyWork(
        userProfile.id,
        dayjs(selectedDate).month() + 1,
        dayjs(selectedDate).year()
      ),
  });

  const { data: timeRecorders, refetch: refetchTimeRecorders } = useQuery({
    queryKey: [
      'TimeRecorderService.list',
      userProfile.id,
      dayjs(selectedDate).month() + 1,
      dayjs(selectedDate).year(),
    ],
    queryFn: () =>
      TimeRecorderService.list(
        userProfile.id,
        dayjs(selectedDate).startOf('month').format('YYYY/MM/DD HH:mm'),
        dayjs(selectedDate).endOf('month').format('YYYY/MM/DD HH:mm')
      ),
  });

  const timeRecorderLeaveType: LeaveTypeResponse = {
    companyId: userProfile.companyId!,
    name: 'Pointeuse',
    order: 0,
    maxDays: 0,
    legalDelay: 0,
    autoValid: true,
    blockingPeriodAllowed: true,
    requiredComment: false,
    requiredAttachment: false,
    companyMandatoryLeaveType: false,
    useAllDays: false,
    color: '#cc5de8',
    excludeEndMonth: true,
    repeatable: false,
    cancelOthersLeaves: false,
    icon: 'clock-play' as IconName,
    id: 'timeRecorderLeaveTypeId',
    showLabel: true,
    tag: LeaveTypeTag.Work,
  };

  const userDailyWork: UserDailyWork | null = useMemo(() => {
    if (!data) return null;

    const _data = cloneDeep(data);

    _data.forEach((dailyWorkDetails: DailyWorkDetails) => {
      if (timeRecorders?.length) {
        const timeRecordedInDay = timeRecorders.filter((timeRecorder) =>
          dayjs(`${timeRecorder.start}Z`).isSame(
            dayjs(dailyWorkDetails.date),
            'day'
          )
        );
        if (timeRecordedInDay.length) {
          let leaves: LeaveDetails[] = [];
          if (dailyWorkDetails.leaves?.length) {
            leaves = cloneDeep(dailyWorkDetails.leaves);
          }
          timeRecordedInDay.forEach((trid: TimeRecorder) => {
            const timeRecorderFoundIndex = leaves?.findIndex(
              (item) => item.id === trid.id
            );
            if (timeRecorderFoundIndex !== -1) {
              leaves[timeRecorderFoundIndex] = {
                ...leaves[timeRecorderFoundIndex],
                period: {
                  start: dayjs(`${trid.start}Z`).valueOf(),
                  end: dayjs(`${trid.end}Z`).valueOf(),
                },
                nbMinutes: trid.duration,
              };
            } else {
              leaves.push({
                id: trid.id,
                period: {
                  start: dayjs(`${trid.start}Z`).valueOf(),
                  end: dayjs(`${trid.end}Z`).valueOf(),
                },
                type: {
                  ...timeRecorderLeaveType,
                  id: trid.leaveId || 'timeRecorderLeaveTypeId',
                },
                nbMinutes: trid.duration,
              });
            }
          });
          dailyWorkDetails.leaves = leaves;
        }
      }
    });

    return {
      userId: userProfile.id,
      status: 'VALID',
      employeeName: userProfile.fullname!,
      dailyWorks: _data,
    };
  }, [data, timeRecorders]);

  const dashboardItems: { title: string; value: string }[] = useMemo(() => {
    if (!data)
      return [
        { title: t('w.totalWorkedHours'), value: '00:00' },
        { title: t('w.totalOvertime'), value: '00:00' },
        { title: t('w.totalOvertimesPaid'), value: '00:00' },
        { title: t('w.totalOvertimesInCounter'), value: '00:00' },
      ];

    return [
      {
        title: t('w.totalWorkedHours'),
        value: convertMinutesToHourMinutes(
          data.reduce((sum, row) => sum + row.workingTimeDay, 0)
        ),
      },
      {
        title: t('w.totalOvertime'),
        value: convertMinutesToHourMinutes(
          data.reduce((sum, row) => sum + row.overtime, 0)
        ),
      },
      {
        title: t('w.totalOvertimesPaid'),
        value: convertMinutesToHourMinutes(
          data.reduce(
            (sum, row) => sum + (row.incrementCounter ? 0 : row.overtime),
            0
          )
        ),
      },
      {
        title: t('w.totalOvertimesInCounter'),
        value: convertMinutesToHourMinutes(
          data.reduce(
            (sum, row) => sum + (row.incrementCounter ? row.overtime : 0),
            0
          )
        ),
      },
    ];
  }, [data]);

  function openImport(date: DateValue) {
    modals.open({
      modalId: `import-user-activities`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.import')}
        </Title>
      ),
      size: 'xl',
      children: (
        <ImportActivitiesForm
          exportClick={() => onExportClick('XLSX', date)}
          refresh={refetch}
        />
      ),
    });
  }

  function onExportClick(type: 'XLSX' | 'PDF', selectedDate: DateValue) {
    if (type === 'XLSX') {
      exportUserDailyWork({
        month: dayjs(selectedDate).month() + 1,
        year: dayjs(selectedDate).year(),
      });
    } else {
      htmlToPdf(
        `user-${userProfile.id}-activities`,
        t,
        `export-activités-${replaceSpaceWithString(
          userProfile.fullname!,
          '-'
        )}-${dayjs(selectedDate).format('MMMM-YYYY')}`
      );
    }
  }

  const actionButtons = useMemo(
    () => [
      <CustomMonthPickerInput
        key={'month-picker'}
        value={selectedDate}
        onChange={(date) => setSelectedDate(date as DateValue)}
      />,
      <Button
        key={'import-button'}
        disabled={
          !hasPermission(
            {
              permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
              scope: ScopeEnum.ALL,
            },
            userProfile
          )
        }
        leftSection={<IconTableDown />}
        bg={'white'}
        variant={'subtle'}
        onClick={() => openImport(selectedDate)}
      >
        {t('w.import')}
      </Button>,
      <CustomMenu
        key={'export-button'}
        buttonLabel={t('w.export')}
        buttonVariant={'filled'}
        leftSection={<IconTableImport />}
        menuLabel={t('w.entityVia', t('w.activities'))}
        menuItems={[
          {
            label: 'XLSX',
            icon: <IconBrandPocket size={18} />,
            onClick: () => onExportClick('XLSX', selectedDate),
          },
          {
            label: 'PDF',
            icon: <IconBrandPocket size={18} />,
            onClick: () => onExportClick('PDF', selectedDate),
          },
        ]}
      />,
    ],
    [selectedDate]
  );

  return (
    <ScreenWrapper
      title={t('w.activities')}
      actionButtons={actionButtons}
      paper
    >
      <SimpleGrid cols={{ base: 2, md: 4, lg: 5 }} mb="md">
        {dashboardItems.map((item) => (
          <DashboardItem key={item.title} {...item} />
        ))}
      </SimpleGrid>
      {userDailyWork ? (
        <MonthEventScheduler
          id={`user-${userProfile.id}-activities`}
          periodDailyWorks={userDailyWork}
          refetchDailyWorkListOnPeriod={refetch}
          userId={userDailyWork.userId}
          /*key={`horizontalMonthDailyWorkInfo_${dayjs(
            selectedDate
          ).toISOString()}}`}*/
          companyId={userProfile.company?.id!}
          refetchTimeRecorders={refetchTimeRecorders}
        />
      ) : (
        <Skeleton visible w="100%" h={400} />
      )}
    </ScreenWrapper>
  );
}
